import Link from 'next/link'

import CSS from './arrow-button.module.css'

export const ArrowButton = ({ href, children, inverse, large, theme, target, rel }) => {

    const handleTheme = ( theme ) => {
        switch ( theme ) {
            case 'secondary' : return CSS.secondary
                break

            case 'accent' : return CSS.accent
                break

            default: return 
        }
    }

    return (
        <Link href={ href } >
            <a 
                className={`
                    ${CSS.arrowButton} 
                    ${inverse ? 'c-white' : 'c-secondary'} 
                    ${large ? CSS.lg : ''}
                    ${handleTheme(theme)}
                `}
                target={target}
                rel={rel}
            >
                { children }
            </a>
        </Link>
    )
}