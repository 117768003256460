import { gql } from "@apollo/client"
import craftApolloClient from "./api/apollo"

import { ParallaxProvider } from 'react-scroll-parallax'

import '../css/index.css'

import { MainMenu } from '../fuselage/global/main-menu/main-menu'
import { ActionsTab } from "../fuselage/components/actions-tab/actions-tab"
import { Footer } from '../fuselage/global/footer/footer'

function App({ Component, pageProps, globals }) {

	// console.log('globals:', globals)

	return (
		<ParallaxProvider>
			<MainMenu mainMenu={globals.nodes} />
			<ActionsTab />
			<Component {...pageProps} />
			<Footer 
				socialConnections={globals.globalSet.socialConnections} 
				contactInfo={globals.globalSet.contactDetails[0].body}
				hubspotForm={globals.globalSet.hubspotForm[0]}
			/>
		</ParallaxProvider>

	)
}


App.getInitialProps = async () => {

	const { data } = await craftApolloClient().query({
		query: gql`
			query Globals {
				nodes(site: "renew", navHandle: "renewMainMenu", level: 1) {
					id
					title
					url
					level
					newWindow
					children {
						id
						title
						url
						level
						newWindow
					}
				}
				globalSet(site: "renew") {
					... on footer_GlobalSet {
						id
						name
						socialConnections {
							... on socialConnections_BlockType {
								id
								hyperlink
								icon
								label
							}
						}
						contactDetails {
							... on contactDetails_BlockType {
								id
								body
							}
						}
						hubspotForm {
							... on hubspotForm_BlockType {
								id
								formId
								portalId
							}
						}
					}
				}
			}
		
		`
	})

	return { globals: data }

}

export default App
