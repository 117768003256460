import React from 'react'
import PropTypes from 'prop-types'
import CSS from './button.module.css'

export const Button = ({ children, href, target, rel, outline, inverse, accent, secondary, decal }) => {
	return (
		<a 
			href={href} 
			className={`
				${CSS.button} 
				${outline ? CSS.outline : '' } 
				${inverse ? CSS.inverse : '' } 
				${accent ? CSS.accent : '' } 
				${secondary ? CSS.secondary : '' } 
				${decal}
			`}
			target={target}
			rel={rel}
		>
			{children}
		</a>
	)
}

Button.propTypes = {
	href: PropTypes.string.isRequired,
	outline: PropTypes.bool,
	decal: PropTypes.string
}

