import Link from 'next/link'
import Image from 'next/image'

import HubspotForm from '../../components/hubspot-form/hubspot-form'
import { ButtonSocial } from  '../../components/button-social/button-social'

import CSS from './footer.module.css'

export const Footer = ({ socialConnections, contactInfo, hubspotForm }) => {

    return (
        <footer className="footer block bg-dark bt-1 bc-white-10" style={{ position: `relative`, zIndex: 1 }}>
            <div className={`${CSS.container} container formatted c-white`}>

                    <section className={CSS.section}>
                        <h5>Want to stay updated on RENEW?</h5>
                        <p>If you haven&apos;t already registered your interest, simply submit your first name and email and we&apos;ll make sure to keep you posted on all the exciting announcements we have in store for the event.</p>
                        <HubspotForm portalId={hubspotForm.portalId} formId={hubspotForm.formId} />
                    </section>
        
                    <section className={`${CSS.section} ${CSS.divider} center`}>
                        <h5>Join the conversation</h5>
                        <p className='fs-3 fw-600'>#the<span className='c-primary'>RENEW</span>event</p>
                        <p className='flex jc-center flex-wrap gap-xxs'>
                            {
                                socialConnections.map( connection => {
                                    return (
                                        <ButtonSocial 
                                            key={connection.id} 
                                            icon={connection.icon} 
                                            url={connection.hyperlink} 
                                            alt={connection.label}
                                        />
                                    )
                                })
                            }
                        </p>

                        <div className='mt-md caps fw-600'>
                            <p><Link href='/contact-us'><a className='uLink'>Contact us</a></Link></p>
                            <p><Link href='/privacy-policy'><a className='uLink'>Privacy Policy</a></Link></p>
                        </div>

                        <div className='mt-md'>
                            <a className={CSS.carbonLogo} href="https://www.co2neutralwebsite.com/certificate/7003/en" target='_blank' rel='noreferrer'>
                                <Image src="/assets/ui/co2-neutral-inverse.svg" width={192} height={80} alt="CO2 neutral"/>
                            </a>
                            <p className='fs-sm fw-400 mt-xs'>Our website and your visit to the website is CO2-neutral</p>
                        </div>
                    </section>
        
                    <section className={`${CSS.section} ${CSS.hosts} ${CSS.divider} center`}>
                        {/* <div>
                            <h5>Host Partner</h5>
                            <a className={CSS.partnerLogo} href="https://www.neom.com/en-us" target='_blank' rel='noreferrer' aria-label="Host Partner">
                                <Image width={198} height={214} src="/assets/site/neom-inverse@2x.png" alt="" />
                            </a>
                        </div> */}
                        <div>
                            <h5>Organiser</h5>
                            <a className={CSS.partnerLogo} href="https://www.thebench.com" target='_blank' rel='noreferrer' aria-label="Organiser">
                                <Image width={221} height={92} src="/assets/site/the-bench-inverse@2x.png" alt="" />
                            </a>
                        </div>

                        {/* <div className="mt-sm formatted">
                            <h5>Contact us</h5>
                            <div dangerouslySetInnerHTML={{__html: contactInfo }} />
                        </div> */}
                    </section>

                

            </div>
                
            <div className="container c-white center">
                <p><small>
                    © {new Date().getFullYear()} The Bench
                </small></p>
            </div>
        </footer>
    )
}