import React, { useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'

import { Button } from '../../components/button/button'

import CSS from './main-menu.module.css'

export const MainMenu = ({ mainMenu }) => {

    const [ active, setActive ] = useState( false )

    return (
        <>
            <div className={CSS.toggleContainer}>
                <div className={`${CSS.toggle} ${ active && CSS.active }`} onClick={ () => setActive(!active) }>
                    { active ? <span className='fs-2 fw-300'>&#215;</span> : <span>MENU</span> }
                </div>
            </div>

            <div className={`${CSS.mask} ${ active && CSS.active }`} onClick={ () => setActive(false)}></div>
            
            <div className={`${CSS.overlay} ${ active && CSS.active } px-lg py-md`}>
                <section>
                    {
                        mainMenu.map( node => {
                            
                                return (
                                    <ul key={node.id}>
                                        { 
                                            !node.url ? 
                                                <li className='mt-xs'><span className='c-white fw-600 caps'>{node.title}</span></li>
                                            : 
                                                <li key={node.id}>
                                                    <Link href={node.url ? node.url : '#'}>
                                                        <a 
                                                            target={ node.newWindow == 1 ? '_blank' : '' } 
                                                            className='uLink fs-5 fw-600' 
                                                            onClick={ () => setActive(false)}
                                                        >
                                                            {node.title}
                                                        </a>
                                                    </Link>
                                                </li> 
                                        }
                                        
                                        {
                                            node.children.map( childNode => (
                                                <li key={childNode.id}>
                                                    <Link href={childNode.url ? childNode.url : '#'}>
                                                        <a 
                                                            target={ childNode.newWindow == 1 ? '_blank' : '' } 
                                                            className='uLink fs-5 fw-300 ml-sm' 
                                                            onClick={ () => setActive(false)}
                                                        >
                                                            {childNode.title}
                                                        </a>
                                                    </Link>                                                
                                                </li>
                                            )
                                        )}
                                    </ul>
                                )
                            }
                        
                        )
                    }

                    <div className='mt-md'>
                        <Button href="/register-interest" decal="fw-700 fs-5" secondary>Register interest</Button>
                    </div>
                </section>

                <section>
                    <div className='mt-xs'>
                        <a className={CSS.carbonLogo} href="https://www.co2neutralwebsite.com/certificate/7003/en" target='_blank' rel='noreferrer'>
                            <Image src="/assets/ui/co2-neutral.svg" width={192} height={80} alt="CO2 neutral" />
                        </a>
                        <p className='fs-sm fw-400 mt-xxs'>Our website and your visit to the website is CO2-neutral</p>
                    </div>
                </section>
            </div>
        </>
    )
}
